import React from 'react';
// import Popup from 'reactjs-popup';
import { NavLink } from 'react-router-dom';

interface IHeaderProps {
  signInState: boolean;
  dataProvider: any;
}

interface IHeaderState {
  contentHeight: string;
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      contentHeight: '92vh'
    };
  }

  //My Account (***201)
  render(): React.ReactNode {
    return (
      <>
        <h1 style={{ backgroundColor: 'darkblue', paddingTop: '10px', color: 'white', textAlign: 'center' }}>
          <NavLink style={{ color: 'white' }} exact={true} to='./'>
            KCenter
          </NavLink>
        </h1>
        <NavLink className='account' exact={true} to='./account'>
          {!this.props.signInState && <span> Login / Sign up </span>}
          {this.props.signInState && <span> My Account ({this.props.dataProvider.getSignInState().userId}) </span>}
        </NavLink>
      </>
    );
  }

  public componentDidMount(): void {}
}

export default Header;
