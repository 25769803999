/// this is a functional component

import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { timeSlots, sqlTimeSlots } from '../dataProvider/TimeSlot';
import { ISeat4Edit } from '../dataProvider/SeatCount';
import { DataProvider } from '../dataProvider/DataProvider';

import './EditSeat.css';

interface IEditSeatProps {
  dataProvider: DataProvider;
  date: Date;
}

const EditSeat = (props: IEditSeatProps) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const [EditSeatState, setEditSeatState] = useState({
    seedSlotState: {} as any,
    seats: [] as ISeat4Edit[]
  });

  const history = useHistory();

  const load = () => {
    //load Offer for props.date, that should be an array, but for now, just pick the first element
    // in the array, until we get another page that renders the list.
    props.dataProvider.getShowSeats2Edit(props.date).then((seats: ISeat4Edit[]) => {
      // compute seedSlotState  --- actually, this is the only state
      const seedSlotState = {} as any;
      seats.forEach((seat, idx) => {
        if (!seedSlotState[seat.sharedSeatId]) {
          seedSlotState[seat.sharedSeatId] = {} as any;
          seedSlotState[seat.sharedSeatId].lots = [] as any[];
          seedSlotState[seat.sharedSeatId].sss = sqlTimeSlots.indexOf(seat.startTime);
          seedSlotState[seat.sharedSeatId].eee = sqlTimeSlots.indexOf(seat.endTime);
          seedSlotState[seat.sharedSeatId].assistantName = seat.assistantName;

          let iii = seedSlotState[seat.sharedSeatId].sss;
          for (; iii < seedSlotState[seat.sharedSeatId].eee; iii++) {
            seedSlotState[seat.sharedSeatId].lots.push({ deleted: true, idx: -1 });
          }
        }
        //TODO: test this lotTime can be null --- when a seat has no lots at all
        if (seat.lotTime) {
          const lot = sqlTimeSlots.indexOf(seat.lotTime);
          seedSlotState[seat.sharedSeatId].lots[lot - seedSlotState[seat.sharedSeatId].sss].deleted = false;
          seedSlotState[seat.sharedSeatId].lots[lot - seedSlotState[seat.sharedSeatId].sss].idx = idx;
        }
      });
      setEditSeatState({ ...EditSeatState, seedSlotState, seats });
    });
    /*
    2. must treat slots sold separately -- <sold> colored differently, and can't change
    3. the update needs to worry about the sharedSeatTimeUnitId --- not creating new.
    4. there might be new, if <delete> state is changed to active state.
    */
  };

  useEffect(load, []);

  const update = () => {
    const seatIds = Object.keys(EditSeatState.seedSlotState);
    let cntDeleted = 0;
    let cntAdded = 0;

    const insertLots = [] as any;
    const deleteLots = [] as any;

    seatIds.forEach((seatId) => {
      EditSeatState.seedSlotState[seatId].lots.forEach((lot: any, jjj: number) => {
        if (lot.deleted) {
          //in delete state, but there was a record
          if (lot.idx >= 0) {
            cntDeleted++;
            deleteLots.push(EditSeatState.seats[lot.idx].seatTimeUnitId);
          }
        } else {
          if (lot.idx === -1) {
            //new active record, there was no record yet
            cntAdded++;
            const pair = [];
            pair.push(seatId);
            pair.push(sqlTimeSlots[jjj + EditSeatState.seedSlotState[seatId].sss]);
            insertLots.push(pair);
          }
        }
      });
    });
    if (cntDeleted === 0 && cntAdded === 0) {
      alert('No change is made');
      return;
    }

    props.dataProvider.updateSeatOffer({ deleteLots, insertLots }).then((res: any) => {
      const success = cntDeleted === res.cntDelete && cntAdded === res.cntInsert;
      const sss = success
        ? `Success: you added ${res.cntInsert} slots and canceled ${res.cntDelete} slots`
        : `Some failure: not all changes made into the system, try again <${res.cntInsert},${res.cntDelete}>`;
      alert(sss);
      history.push('./');
    });
  };

  const cancel = () => {
    history.push('./');
  };

  const displaySlot = (e: any) => {
    const ids = e.target.id.split('_');
    const jjj = parseInt(ids[1]);

    const { seats, seedSlotState } = EditSeatState;
    const idx = seedSlotState[ids[0]].lots[jjj].idx;
    const tS = jjj + seedSlotState[ids[0]].sss;

    const sss = `Seat#${ids[0]}, time: ${timeSlots[tS]}\nPurchase Center ID: ${
      seats[idx].purchaseUserAccount
    }\nAdditional instruction: ${seats[idx].messageToAssistant || 'none'}\nOnline Info: ${
      seats[idx].onlineInfo || 'none'
    }`;
    alert(sss);
  };

  const editSeatSlot = (e: any) => {
    const ids = e.target.id.split('_');
    const lotS = parseInt(ids[1]);

    const { seedSlotState } = EditSeatState;
    seedSlotState[ids[0]].lots[lotS].deleted = !seedSlotState[ids[0]].lots[lotS].deleted;

    setEditSeatState({ ...EditSeatState, seedSlotState });
  };

  /**** we might wnat onlineInfo, and assist to be change-able on the screen,
   * //but it's not in the spec, so skip for now
  const changeOI = (e: any) => {
    setEditSeatState({ ...EditSeatState, onlineInfo: e.target.value });
  };

  const changeAs = (e: any) => {
    setEditSeatState({ ...EditSeatState, assist: e.target.value });
  };
            <tr>
              <th colSpan={2}/>
              <th/>
            </tr>
  */

  function buttonLot(seatId: string, lot: any, iii: number, jjj: number) {
    const { seats } = EditSeatState;

    const unSold = !(lot.idx >= 0 && seats[lot.idx] && seats[lot.idx].purchaseUserAccount);
    const cN = lot.deleted ? 'lotDeleted' : unSold ? 'lotActive' : 'lotSold';
    const tx = lot.deleted ? 'Deleted' : unSold ? 'Active' : 'Sold';
    const db = cN === 'lotSold';
    const id = seatId + '_' + jjj;
    const ts = jjj + EditSeatState.seedSlotState[seatId].sss;

    if (db) {
      return (
        <button id={id} key={(iii + 1) * 100 + jjj} className={cN} onClick={displaySlot}>
          {timeSlots[ts]} {tx}
        </button>
      );
    } else {
      return (
        <button id={id} key={(iii + 1) * 100 + jjj} className={cN} onClick={editSeatSlot}>
          {timeSlots[ts]} {tx}
        </button>
      );
    }
  }

  function editOffer() {
    const seatIds = Object.keys(EditSeatState.seedSlotState);
    return (
      <div className='editSeat'>
        <h2>Update Shared Class Seat Offer for {props.date.toLocaleDateString(undefined, options)}</h2>
        <div className='tableOfSeat'>
          <table>
            <tbody>
              {seatIds.map((seatId, iii) => {
                return (
                  <tr key={iii}>
                    <td className='ll2'>Seat# {seatId} </td>
                    <td className='ll2'>{EditSeatState.seedSlotState[seatId].assistantName} </td>
                    <td>
                      {EditSeatState.seedSlotState[seatId].lots.map((lot: any, jjj: number) => {
                        return buttonLot(seatId, lot, iii, jjj);
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <p style={{ textAlign: 'center' }}>Click on sold slot to see the instructions from the purchaser</p>
        <button style={{ marginLeft: '400px', width: '80px', marginTop: '25px', marginRight: '20px' }} onClick={cancel}>
          <b>Back</b>
        </button>
        <button style={{ width: '80px', marginTop: '25px' }} onClick={update}>
          <b>Update</b>
        </button>
      </div>
    );
  }

  return editOffer();
};

export default EditSeat;
