/// this is a functional component

//import { allowScrollOnElement } from '@fluentui/react';
import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { timeSlots } from '../dataProvider/TimeSlot';

import './PostSeat.css';

interface IPostSeatProps {
  dataProvider: any;
  signIn: boolean;
}

const PostSeat = (props: IPostSeatProps) => {
  function getYYYYMMDD(d: Date) {
    return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;
  }

  const [PostSeatState, setPostSeatState] = useState({
    stage: 0,
    dt: getYYYYMMDD(new Date()),
    assist: '',
    onlineInfo: '',
    startT: timeSlots[8],
    endT: timeSlots[21],
    seedN: 1,
    recurring: false,
    repeatCount: 0,
    seedSlotState: [false]
  });

  const history = useHistory();

  const complete = () => {
    const s = timeSlots.indexOf(PostSeatState.startT);
    const e = timeSlots.indexOf(PostSeatState.endT);
    let count = (e - s) * PostSeatState.seedN;

    PostSeatState.seedSlotState.forEach((aaa) => {
      if (aaa) count--;
    });
    if (count === 0) {
      alert('There must be at least one seat unit. Please try again!');
      return;
    }

    props.dataProvider.createSeatOffer(PostSeatState).then((success: boolean) => {
      if (success) history.push('./');
      else alert('Post offer failed, nework might be down, try again!');
    });
  };

  const cancel = () => {
    history.push('./');
  };

  const editSeatSlot = (e: any) => {
    const { seedSlotState } = PostSeatState;
    seedSlotState[e.target.id] = !seedSlotState[e.target.id];

    setPostSeatState({ ...PostSeatState, seedSlotState });
  };
  const pickStartTime = (e: any) => {
    setPostSeatState({ ...PostSeatState, startT: e.target.value });
    console.log(e.target.value);
  };
  const pickEndTime = (e: any) => {
    setPostSeatState({ ...PostSeatState, endT: e.target.value });
  };
  const pickSeedN = (e: any) => {
    setPostSeatState({ ...PostSeatState, seedN: e.target.value });
  };
  const changeOI = (e: any) => {
    setPostSeatState({ ...PostSeatState, onlineInfo: e.target.value });
  };
  const changeDt = (e: any) => {
    setPostSeatState({ ...PostSeatState, dt: e.target.value });
  };

  const changeAs = (e: any) => {
    setPostSeatState({ ...PostSeatState, assist: e.target.value });
  };

  const setRepeatState = (e: any) => {
    setPostSeatState({ ...PostSeatState, recurring: e.target.checked });
  };

  const changeRecurringCount = (e: any) => {
    setPostSeatState({ ...PostSeatState, repeatCount: e.target.value });
  };

  const next = () => {
    const s = timeSlots.indexOf(PostSeatState.startT);
    const e = timeSlots.indexOf(PostSeatState.endT);

    if (e <= s) {
      alert('End time must be bigger than the start time');
      return;
    }
    if (s + 13 < e) {
      alert('Total time slots should not be more than 13');
      return;
    }
    if (PostSeatState.recurring === true && PostSeatState.repeatCount <= 0) {
      alert('Must put valid number for recurring event: 1 to 8');
      return;
    }
    if (PostSeatState.repeatCount > 8) {
      alert('Seats can be repeated up to 8 times');
      return;
    }
    setPostSeatState({ ...PostSeatState, stage: 1, seedSlotState: [] });
  };

  const back = () => {
    setPostSeatState({ ...PostSeatState, stage: 0 });
  };

  //later: need to learn how to customize the drop down arrow
  //  https://code-boxx.com/dropdown-arrow-css/
  //            <input type='text' id='idStartTime' name='startTime'/>

  function stage0Layout() {
    return (
      <div className='PostSeat'>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr style={{ padding: '20px' }}>
              <td className='left'> </td>
              <td>
                {' '}
                <h4 className='h3Title'> Create New Shared Class Seat Offer </h4>{' '}
              </td>
            </tr>
            <tr>
              <td className='left'> Select the class date </td>
              <td>
                {' '}
                <input type='date' value={PostSeatState.dt} onChange={changeDt} style={{ width: '200px' }} />{' '}
              </td>
            </tr>
            <tr>
              <td className='left'></td>
              <td>
                {' '}
                <input
                  type='checkbox'
                  id='recurring'
                  name='recurring'
                  onChange={setRepeatState}
                  checked={PostSeatState.recurring}></input>
                <label> Recur weekly for </label>
                <input
                  className='quantity'
                  id='number'
                  type='number'
                  value={PostSeatState.repeatCount}
                  onChange={changeRecurringCount}
                  min={0}
                  max={8}
                  style={{ width: '40px' }}
                />
                <label> weeks</label>
              </td>
            </tr>

            <tr>
              <td className='left'> Assistant Name </td>
              <td>
                {' '}
                <input type='text' value={PostSeatState.assist} onChange={changeAs} style={{ width: '200px' }} />{' '}
              </td>
            </tr>
            <tr>
              <td className='left'> Online Info </td>
              <td>
                {' '}
                <textarea
                  value={PostSeatState.onlineInfo}
                  rows={3}
                  cols={35}
                  className='onlineInfo'
                  onChange={changeOI}
                />{' '}
              </td>
            </tr>
            <tr>
              <td className='left'> Start time </td>
              <td>
                <select value={PostSeatState.startT} name='optionlist' onChange={pickStartTime}>
                  {timeSlots.map((ts, index) => {
                    return <option key={index + 1}>{ts}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td className='left'> End time </td>
              <td>
                <select value={PostSeatState.endT} name='optionlist' onChange={pickEndTime}>
                  {timeSlots.map((ts, index) => {
                    return <option key={(index + 1) * 100}>{ts}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td className='left'> Number of seats </td>
              <td>
                <select name='optionlist' value={PostSeatState.seedN} onChange={pickSeedN}>
                  ` <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>`{' '}
                </select>
              </td>
            </tr>
            <tr>
              <td className='left'> </td>
              <td>
                <button style={{ width: '80px', marginTop: '50px', marginRight: '20px' }} onClick={cancel}>
                  <b>Cancel</b>
                </button>
                <button style={{ width: '80px', marginTop: '50px' }} onClick={next}>
                  <b>Next</b>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function stage1Layout() {
    // constuct the list of rows for each seat
    const s = timeSlots.indexOf(PostSeatState.startT);
    const e = timeSlots.indexOf(PostSeatState.endT);
    const tS = timeSlots.slice(s, e);
    console.log(tS);
    const listOfSeats = [];
    listOfSeats.push(
      <tr key={100}>
        <td className='left'> </td>
        <td>
          {tS.map((timeString, index) => {
            return (
              <button key={index} className='seatHeading'>
                {' '}
                {timeString}{' '}
              </button>
            );
          })}
        </td>
      </tr>
    );
    for (let i = 0; i < PostSeatState.seedN; i++) {
      listOfSeats.push(
        <tr key={100 + i + 1}>
          <td className='left' style={{ verticalAlign: 'middle' }}>
            {' '}
            Seat #{i + 1}{' '}
          </td>
          <td>
            {tS.map((timeString, index) => {
              return (
                <button
                  id={`${i * tS.length + index}`}
                  key={100 + i * tS.length + index}
                  className='seatActive'
                  style={{ color: !PostSeatState.seedSlotState[i * tS.length + index] ? 'green' : 'brown' }}
                  onClick={editSeatSlot}>
                  {!PostSeatState.seedSlotState[i * tS.length + index] ? 'Active' : 'Deleted'}
                </button>
              );
            })}
          </td>
        </tr>
      );
    }

    return (
      <div className='PostSeatStage1'>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr style={{ padding: '20px' }}>
              <td className='left'> </td>
              <td>
                {' '}
                <h4 className='h3Title'> Create New Shared Class Seat Offer </h4>{' '}
              </td>
            </tr>
            <tr>
              <td className='left'> Select the class date </td>
              <td>{PostSeatState.dt}</td>
            </tr>
            <tr>
              <td className='left'> Recur weekly </td>
              <td>{PostSeatState.recurring ? `Yes for ${PostSeatState.repeatCount} weeks` : 'No'}</td>
            </tr>
            <tr>
              <td className='left'> Assistant Name </td>
              <td>{PostSeatState.assist}</td>
            </tr>
            <tr>
              <td className='left'> Online Info </td>
              <td>
                {PostSeatState.onlineInfo.split('\n').map((tx, idx) => {
                  return (
                    <span key={idx + 1}>
                      {tx} <br />
                    </span>
                  );
                })}
                <br />
              </td>
            </tr>
            {listOfSeats}
            <tr>
              <td className='left'> </td>
              <td>
                Click on the seat time unit
                <button style={{ position: 'relative', top: '4px', margin: '4px', width: '25px', height: '25px' }} />
                to make change
              </td>
            </tr>
            <tr>
              <td className='left'> </td>
              <td>
                <button style={{ width: '80px', marginTop: '25px', marginRight: '20px' }} onClick={back}>
                  <b>Back</b>
                </button>
                <button style={{ width: '80px', marginTop: '25px' }} onClick={complete}>
                  <b>Complete</b>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return PostSeatState.stage ? stage1Layout() : stage0Layout();
};

export default PostSeat;
