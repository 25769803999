import React from 'react';
//import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { ISeatCount } from '../dataProvider/SeatCount';
import { DataProvider } from '../dataProvider/DataProvider';

import './Calendar.css';

//    app.get('/sharedTime/getUnpurchasedSeats', useAuth, getUnpurchasedSeats);
//    app.get('/sharedTime/showOfferedSeat', useAuth, showOfferedSeat);
//    app.get('/sharedTime/showPurchasedSeat', useAuth, showPurchasedSeat);

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

interface ICalendarProps {
  setDateDetail: any;
  dataProvider: DataProvider;
}

interface ICalendarState {
  currentMonthDate: Date;
  unPurchasedSeats: ISeatCount[];
  seatsOffered: ISeatCount[];
  seatsPurchased: ISeatCount[];
}

/*
const startYear = 2000;
const endYear = 2020;
*/

//content.position?.day

class Calendar extends React.Component<ICalendarProps, ICalendarState> {
  constructor(props: any) {
    super(props);

    const now = new Date();
    this.state = {
      currentMonthDate: new Date(now.getFullYear(), now.getMonth(), 5),
      unPurchasedSeats: [],
      seatsOffered: [],
      seatsPurchased: []
    };
  }

  private async load() {
    const unPurchasedSeats = await this.props.dataProvider.getUnPurchasedSeats(this.state.currentMonthDate);
    const seatsOffered = await this.props.dataProvider.getSeatsOffered(this.state.currentMonthDate);
    const seatsPurchased = await this.props.dataProvider.getseatsPurchased(this.state.currentMonthDate);
    this.setState({ unPurchasedSeats, seatsOffered, seatsPurchased });
  }

  /*
  private handlePostClick = (e:any) => {
    if (!this.props.dataProvider.isSignedIn()) {
      e.preventDefault();
    }
  }
  */

  public componentDidMount(): void {
    console.log('Calendar componentDidMount');
    this.load();
  }

  render(): React.ReactNode {
    return (
      <>
        <table className='headerTable'>
          <tbody style={{ width: '100%' }}>
            <tr>
              <td className='headerTd'>
                <div
                  style={{ width: '10px', height: '10px', backgroundColor: 'darkblue', display: 'inline-block' }}></div>
                &nbsp;&nbsp;Available seats
              </td>
              <td className='headerTd'>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'green',
                    display: 'inline-block'
                  }}></div>
                &nbsp;&nbsp;Seats I am offering
              </td>
              <td className='headerTd'>
                <div style={{ width: '10px', height: '10px', backgroundColor: 'brown', display: 'inline-block' }}></div>
                &nbsp;&nbsp;Seats I have purchased
              </td>
              <NavLink to='./postoffer'>
                <td className='headerTd'>
                  <button style={{ width: '100%' }} disabled={!this.props.dataProvider.isSignedIn()}>
                    &nbsp;&nbsp;+ Post a new seat offer&nbsp;&nbsp;
                  </button>
                </td>
              </NavLink>
            </tr>
          </tbody>
        </table>
        <div className='calendar'>
          <div style={{ display: 'inline-block', fontSize: '20px', marginLeft: '6px', marginTop: '10px' }}>
            <b>{monthNames[this.state.currentMonthDate.getMonth()]}</b> &nbsp;
            {this.state.currentMonthDate.getFullYear()}
          </div>
          <div style={{ float: 'right', marginRight: '4px', marginTop: '10px' }}>
            <button style={{ marginLeft: '4px' }} onClick={this.back1Month}>
              {' '}
              {'<'}{' '}
            </button>
            <button style={{ marginLeft: '4px' }} onClick={this.thisMonth}>
              {' '}
              today{' '}
            </button>
            <button style={{ marginLeft: '4px' }} onClick={this.forward1Month}>
              {' '}
              {'>'}{' '}
            </button>
          </div>
          <div
            className='calendar-dates'
            onClick={(e: any) => {
              const ds = e.target.id?.split('_');
              if (ds && ds.length > 3) {
                const tD = new Date(this.state.currentMonthDate.getFullYear(), ds[1], ds[2]);
                this.props.setDateDetail(tD);
                // @ts-ignore: history property exits, but not in the property interface definition
                this.props.history.push(ds[3] === 'G' ? './editoffer' : './PurchaseSeat');
              }
            }}>
            <div className='days'>
              {dayOfWeek.map((day, index) => {
                return (
                  <div key={(index + 1) * 10000} className='day label'>
                    {day}
                  </div>
                );
              })}
            </div>
            {this.loadCalendarDays()}
          </div>
        </div>
      </>
    );
  }

  back1Month = () => {
    const currentMonthDate = new Date(
      this.state.currentMonthDate.getFullYear(),
      this.state.currentMonthDate.getMonth() - 1,
      5
    );
    this.setState({ currentMonthDate }, this.load);
  };

  thisMonth = () => {
    const now = new Date();
    const currentMonthDate = new Date(now.getFullYear(), now.getMonth(), 5);
    this.setState({ currentMonthDate }, this.load);
  };

  forward1Month = () => {
    const currentMonthDate = new Date(
      this.state.currentMonthDate.getFullYear(),
      this.state.currentMonthDate.getMonth() + 1,
      5
    );
    this.setState({ currentMonthDate }, this.load);
  };

  private showSeatsFor(year: number, month: number, day: number) {
    const unPurchasedCount = this.state.unPurchasedSeats.find((item) => {
      const dd = new Date(item.date);
      const dn = new Date(year, month, day);
      return dd.getFullYear() === dn.getFullYear() && dd.getMonth() === dn.getMonth() && dd.getDate() === dn.getDate();
    });
    const seatsOfferedCount = this.state.seatsOffered.find((item) => {
      const dd = new Date(item.date);
      const dn = new Date(year, month, day);
      return dd.getFullYear() === dn.getFullYear() && dd.getMonth() === dn.getMonth() && dd.getDate() === dn.getDate();
    });
    const seatsPurchasedCount = this.state.seatsPurchased.find((item) => {
      const dd = new Date(item.date);
      const dn = new Date(year, month, day);
      return dd.getFullYear() === dn.getFullYear() && dd.getMonth() === dn.getMonth() && dd.getDate() === dn.getDate();
    });

    if (!(unPurchasedCount?.seatCount || seatsOfferedCount?.seatCount || seatsPurchasedCount?.seatCount)) return;

    return (
      <div style={{ marginTop: '5px' }}>
        {seatsOfferedCount?.seatCount && (
          <button id={'calendarday_' + month + '_' + day + '_G'} className='detailButton greenButton'>
            {seatsOfferedCount?.seatCount}
          </button>
        )}
        {seatsPurchasedCount?.seatCount && (
          <button id={'calendarday_' + month + '_' + day + '_B'} className='detailButton brownButton'>
            {seatsPurchasedCount?.seatCount}
          </button>
        )}
        {unPurchasedCount?.seatCount && (
          <button id={'calendarday_' + month + '_' + day + '_DB'} className='detailButton dbButton'>
            {unPurchasedCount?.seatCount}
          </button>
        )}
      </div>
    );
  }

  private loadCalendarDays() {
    const year = this.state.currentMonthDate.getFullYear();
    const month = this.state.currentMonthDate.getMonth();
    const tD = new Date();
    const fThisMonth = tD.getFullYear() === year && tD.getMonth() === month;

    const tmpDate = new Date(year, month, 0);
    const num = this.daysInMonth(month, year);
    const dayofweek = tmpDate.getDay(); // find where to start calendar day of week

    //add last month dates
    const listOfDays = [];
    for (let i = 0; i <= dayofweek; i++) {
      listOfDays.push(
        <div
          key={i}
          id={'calendarday_' + (month - 1) + '_' + (tmpDate.getDate() - dayofweek + i)}
          className='day date over'
          style={{ backgroundColor: (i + 1) % 7 <= 1 ? 'lightgrey' : 'white' }}>
          {tmpDate.getDate() - dayofweek + i}
          {this.showSeatsFor(year, month - 1, tmpDate.getDate() - dayofweek + i)}
        </div>
      );
    }

    // add this month dates
    listOfDays.push(
      <div
        key={10}
        id={'calendarday_' + month + '_' + (0 + 1)}
        className='day date'
        style={{ backgroundColor: (0 + dayofweek + 2) % 7 <= 1 ? 'lightgrey' : 'white' }}>
        {monthNames[month].slice(0, 3)} &nbsp;
        {fThisMonth && tD.getDate() === 1 && (
          <span style={{ padding: '2px', backgroundColor: 'red', color: 'white', borderRadius: '8px' }}> {1} </span>
        )}
        {!(fThisMonth && tD.getDate() === 1) && 1}
        {this.showSeatsFor(year, month, 1)}
      </div>
    );
    for (let i = 1; i < num; i++) {
      listOfDays.push(
        <div
          key={(i + 1) * 10}
          id={'calendarday_' + month + '_' + (i + 1)}
          className='day date'
          style={{ backgroundColor: (i + dayofweek + 2) % 7 <= 1 ? 'lightgrey' : 'white' }}>
          {fThisMonth && tD.getDate() === i + 1 && (
            <span style={{ padding: '2px', backgroundColor: 'red', color: 'white', borderRadius: '8px' }}>
              {' '}
              {i + 1}{' '}
            </span>
          )}
          {!(fThisMonth && tD.getDate() === i + 1) && i + 1}
          {this.showSeatsFor(year, month, i + 1)}
        </div>
      );
    }

    // add next month dates
    const totD = num + dayofweek + 1;
    const moreDates = 7 - (totD % 7) + (totD >= 35 ? 0 : 7);
    for (let i = 0; i < moreDates; i++) {
      listOfDays.push(
        <div
          key={(i + 1) * 1000}
          id={'calendarday_' + (month + 1) + '_' + (i + 1)}
          className='day date over'
          style={{ backgroundColor: (i + totD + 1) % 7 <= 1 ? 'lightgrey' : 'white' }}>
          {i + 1}
          {this.showSeatsFor(year, month + 1, i + 1)}
        </div>
      );
    }

    return (
      <div id='calendarDays' className='days'>
        {listOfDays}
      </div>
    );
  }

  /*
  public componentDidMount(): void {
    var date = new Date();
    month = date.getMonth();
    year = date.getFullYear();
    document.getElementById("curMonth").innerHTML = months[month];
    document.getElementById("curYear").innerHTML = year;
    loadCalendarMonths();
    loadCalendarYears();
    loadCalendarDays();
  }
  
  private loadCalendarMonths() {
    for (var i = 0; i < months.length; i++) {
        var doc = document.createElement("div");
        doc.innerHTML = months[i];
        doc.classList.add("dropdown-item");

        doc.onclick = (function () {
            var selectedMonth = i;
            return function () {
                month = selectedMonth;
                document.getElementById("curMonth").innerHTML = months[month];
                loadCalendarDays();
                return month;
            }
        })();

        document.getElementById("months").appendChild(doc);
    }
  }
  
  private loadCalendarYears() {
    document.getElementById("years").innerHTML = "";

    for (var i = startYear; i <= endYear; i++) {
        var doc = document.createElement("div");
        doc.innerHTML = i;
        doc.classList.add("dropdown-item");

        doc.onclick = (function () {
            var selectedYear = i;
            return function () {
                year = selectedYear;
                document.getElementById("curYear").innerHTML = year;
                loadCalendarDays();
                return year;
            }
        })();

        document.getElementById("years").appendChild(doc);
    }
  }
  */

  private daysInMonth(month: number, year: number) {
    const d = new Date(year, month + 1, 0);
    return d.getDate();
  }
}

export default Calendar;
