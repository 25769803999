/// this is a functional component

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ISeat } from '../dataProvider/SeatCount';
import { DataProvider } from '../dataProvider/DataProvider';

import './PurchaseSeat.css';

import { timeSlots, sqlTimeSlots } from '../dataProvider/TimeSlot';

//const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

interface IPurchaseSeatProps {
  dataProvider: DataProvider;
  currentDate: Date;
  signIn: boolean;
}

interface IPurchaseSeatState {
  currentDate: Date;
}

const PurchaseSeat = (props: IPurchaseSeatProps) => {
  const [seats4Date, setSeats4Date] = useState({
    currentDate: props.currentDate,
    seats: [] as ISeat[],
    stage: 0
  });

  let cntDeleted = 0;
  let cntPurchased = 0;

  let purchase = [] as any[];
  let purchaseDisplay = [] as any[];
  let cancelPurchase = [] as any[];

  const load = () => {
    props.dataProvider.showSeatTimeUnit(seats4Date.currentDate).then((seats: ISeat[]) => {
      setSeats4Date({ ...seats4Date, seats });
    });
  };

  useEffect(load, [seats4Date.currentDate]);

  const history = useHistory();

  function messageToAssistantChange(e: any) {
    const jj = parseInt(e.target.id || '-1');
    if (jj < 0) return;

    const { seats } = seats4Date;
    seats[jj].messageToAssistant = e.target.value;
    setSeats4Date({ ...seats4Date, seats });
  }

  const back = () => {
    setSeats4Date({ ...seats4Date, stage: 0 });
  };

  const loginSignup = () => {
    history.push('./account');
  };

  const back1Day = () => {
    const { currentDate } = seats4Date;
    const cD = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
    setSeats4Date({ ...seats4Date, currentDate: cD });
  };

  const thisDay = () => {
    setSeats4Date({ ...seats4Date, currentDate: new Date() });
  };

  const forward1Day = () => {
    const { currentDate } = seats4Date;
    const cD = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
    setSeats4Date({ ...seats4Date, currentDate: cD });
  };

  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const cellSelect = (e: any) => {
    console.log(e.target.id);
    if (!e.target.id) return;
    if (e.target.id.startsWith('dd')) return;

    const jj = parseInt(e.target.id);

    const { seats } = seats4Date;
    seats[jj].userWantChange = !seats[jj].userWantChange;
    setSeats4Date({ ...seats4Date, seats });
  };

  /*
export interface ISeat {
  userId: number;
  sharedSeatId: number;
  displayName: string;
  assistantName: string;
  seatTimeUnitId: number; /// this will be important for update/purchases ===
  startTime: string;
//// actually, there will be multiple records for the same seatId, and we need to chain all the startTime together
/// into an array of sort ===
}*/
  function bgColor4Cell(seat: ISeat) {
    if (!seat.purchaseUserAccount) {
      return seat.userWantChange ? 'brown' : '#BCCBDE';
    } else {
      return seat.userWantChange ? '#BCCBDE' : 'brown';
    }
  }

  //  this object will be used to render the 'addInstruction' screen,
  //  as well as to send to sever for update
  //  {
  //    purchase: [{sharedSeatID, seatTimeUnitIds:[seatTimeUnitId], messageToAssistant}],
  //    cancelPurchase: [{sharedSeatID, seatTimeUnitIds:[seatTimeUnitId]}]
  //  }
  //
  function summaryOfChange() {
    purchase = [] as any[];
    cancelPurchase = [] as any[];
    purchaseDisplay = [] as any[];
    cntDeleted = 0;
    cntPurchased = 0;

    let jj = 0;
    while (jj < seats4Date.seats.length) {
      let seat = seats4Date.seats[jj];
      const sharedSeatId = seat.sharedSeatId;
      const displayName = seat.displayName;
      const assistantName = seat.assistantName;

      const pSeatTimeUnitIds = [] as number[];
      const cSeatTimeUnitIds = [] as number[];
      const startTimes = [] as string[];

      let messageToAssistant = '';

      // assuming no dup, or alraedy deduped
      while (seats4Date.seats[jj].sharedSeatId === sharedSeatId) {
        seat = seats4Date.seats[jj];
        if (bgColor4Cell(seat) === 'brown') {
          pSeatTimeUnitIds.push(seat.seatTimeUnitId);
          startTimes.push(seat.startTime);
          cntPurchased++;
        } else {
          if (seat.userWantChange) {
            cSeatTimeUnitIds.push(seat.seatTimeUnitId);
            cntDeleted++;
          }
        }
        if (seat.messageToAssistant) {
          messageToAssistant = seat.messageToAssistant;
        }
        jj++;
        if (jj >= seats4Date.seats.length) break;
      }
      if (pSeatTimeUnitIds.length !== 0) {
        purchase.push({ sharedSeatId, messageToAssistant, seatTimeUnitIds: pSeatTimeUnitIds });
        purchaseDisplay.push({ displayName, assistantName, messageToAssistant, startTimes, seatIdx: jj - 1 });
      }
      if (cSeatTimeUnitIds.length !== 0) {
        cancelPurchase.push({ sharedSeatId, seatTimeUnitIds: cSeatTimeUnitIds });
      }
    }
  }

  function showPurchasedSeatSlot() {
    summaryOfChange();

    const tRA = [] as any[];
    for (let kk = 0; kk < purchase.length; kk++) {
      const tDA = [] as any;
      tDA.push(<td>{purchase[kk].sharedSeatId}</td>);
      tDA.push(
        <td>
          {purchaseDisplay[kk].displayName} <br /> {purchaseDisplay[kk].assistantName}
        </td>
      );
      const divR = [] as any[];
      const jj = 0;
      for (let jj = 0; jj < purchaseDisplay[kk].startTimes.length; jj++) {
        divR.push(
          //jj is the id, index into seatarray, === that's good enough
          <div
            key={jj}
            style={{
              color: 'white',
              display: 'flex',
              float: 'left',
              width: '48px',
              height: '42px',
              backgroundColor: 'brown',
              border: '1px solid white'
            }}>
            {timeSlots[sqlTimeSlots.indexOf(purchaseDisplay[kk].startTimes[jj])]}
          </div>
        );
      }

      tDA.push(
        <td>
          {divR}
          <textarea
            placeholder='(Optional instruction to assistant)'
            value={purchaseDisplay[kk].messageToAssistant}
            style={{
              width: '300px',
              background: 'lightblue',
              display: 'flex',
              position: 'relative',
              left: '4px',
              height: '35px'
            }}
            id={purchaseDisplay[kk].seatIdx.toString()}
            key={jj}
            onChange={messageToAssistantChange}
          />
        </td>
      );
      tRA.push(tDA);
    }

    return tRA.map((tR, idx) => {
      return <tr key={idx * 100}>{tR}</tr>;
    });
  }

  // now send it all to the server through API
  const confirmUpdate = () => {
    summaryOfChange();
    props.dataProvider.updateSeatTimeUnit({ purchase, cancelPurchase }).then((response: any) => {
      const success = cntDeleted === response.cntCancel && cntPurchased === response.cntPurchase;
      const sss = success
        ? `Success: you purchased ${response.cntPurchase} slots and canceled ${response.cntCancel} slots`
        : `Some failure: not all changes made into the system, try again <${response.cntPurchase},${response.cntCancel}>`;
      alert(sss);
      history.push('./');
    });
  };

  const next = () => {
    summaryOfChange();
    let cnt = 0;
    const { seats } = seats4Date;
    for (let jj = 0; jj < seats.length; jj++) {
      if (seats[jj].userWantChange) cnt++;
    }
    if (cnt === 0 && purchase.length === 0) {
      alert('You have made zero change and no purchase');
      return;
    }

    const tNow = new Date();
    const td = new Date(tNow.getFullYear(), tNow.getMonth(), tNow.getDate());
    //const cD = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    if (td.getTime() > seats4Date.currentDate.getTime()) {
      alert('Can not change data past the present time');
      return;
    }
    setSeats4Date({ ...seats4Date, stage: 1 });
  };

  const display = (e: any) => {
    //prevent this event to propogate.
    e.preventDefault();
    if (!e.target.id) return;

    const jj = parseInt(e.target.id.slice(2));
    const sss = `Seat#${seats4Date.seats[jj].sharedSeatId}\nPurchase Center ID: ${
      seats4Date.seats[jj].userId
    }\nOnline Info: ${seats4Date.seats[jj].onlineInfo || 'none'}`;
    alert(sss);
  };

  function showSeatAvailableSlot() {
    const tRA = [] as any[];
    let jj = 0;
    while (jj < seats4Date.seats.length) {
      const tDA = [] as any;
      let seat = seats4Date.seats[jj];
      tDA.push(
        <td id={'dd' + jj} style={{ background: '#BCCBDE' }} onClick={display}>
          {seat.sharedSeatId}
        </td>
      );
      tDA.push(
        <td id={'dd' + jj} style={{ background: '#BCCBDE' }} onClick={display}>
          {seat.displayName} <br /> {seat.assistantName}
        </td>
      );

      const seatId = seat.sharedSeatId;
      let ii = 0;
      /*
        while(seats4Date.seats[jj+1]?.sharedSeatId === seats4Date.seats[jj].sharedSeatId
              && seats4Date.seats[jj+1].startTime === seats4Date.seats[jj].startTime) {
            jj++; //dedup
        }
        */
      // assuming no dup, or alraedy deduped
      while (seats4Date.seats[jj].sharedSeatId === seatId) {
        seat = seats4Date.seats[jj];
        ///toDO bugbug: does string comparion work here??
        while (sqlTimeSlots[ii] < seat.startTime) {
          tDA.push(<td></td>);
          ii++;
          if (ii >= sqlTimeSlots.length) break;
        }
        tDA.push(
          //jj is the id, index into seatarray, === that's good enough
          <td
            id={`${jj}`}
            key={jj}
            style={{
              backgroundColor: bgColor4Cell(seats4Date.seats[jj])
            }}>
            {bgColor4Cell(seats4Date.seats[jj]) === '#BCCBDE' ? 'Avail-able' : ' '}
          </td>
        );
        ii++;
        jj++;
        if (jj >= seats4Date.seats.length) break;
      }

      while (ii < sqlTimeSlots.length) {
        tDA.push(<td></td>);
        ii++;
      }
      tRA.push(tDA);
    }

    return tRA.map((tR, idx) => {
      return <tr key={idx * 100}>{tR}</tr>;
    });
  }

  function pickUnits() {
    return (
      <div className='PurchaseSeat'>
        <h2 style={{ marginTop: '30px' }}>{seats4Date.currentDate.toLocaleDateString(undefined, options)}</h2>
        <div style={{ marginLeft: '75%', marginTop: '10px' }}>
          <button style={{ marginLeft: '4px' }} onClick={back1Day}>
            {' '}
            {'<'}{' '}
          </button>
          <button style={{ marginLeft: '4px' }} onClick={thisDay}>
            {' '}
            today{' '}
          </button>
          <button style={{ marginLeft: '4px' }} onClick={forward1Day}>
            {' '}
            {'>'}{' '}
          </button>
        </div>
        <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
          <table className='daySeat' onClick={cellSelect}>
            <tbody>
              <tr>
                <th style={{ width: '40px' }}> #ID </th>
                <th style={{ width: '100px', minWidth: '100px' }}> Center Name </th>
                {timeSlots.map((timeString, index) => {
                  return (
                    <th key={index} style={{ width: '41px', height: '40px' }}>
                      {' '}
                      {timeString}{' '}
                    </th>
                  );
                })}
              </tr>
              {showSeatAvailableSlot()}
            </tbody>
          </table>
        </div>
        {!props.signIn && (
          <div style={{ marginTop: '40px' }}>
            Please click{' '}
            <button style={{ padding: '10px' }} onClick={loginSignup}>
              Login / Signup{' '}
            </button>{' '}
            to make seat purchase
          </div>
        )}
        {props.signIn && (
          <div style={{ marginTop: '40px' }}>
            <p>Click on the seat ID or Center Name to see online info</p>
            {!!seats4Date.seats.length && <p>Click on non-empty cell to make a purchase or cancel a purchase</p>}
            {!seats4Date.seats.length && <p style={{ color: 'brown' }}>No data for today</p>}
            <button style={{ width: '120px' }} onClick={() => history.push('./')}>
              Back
            </button>
            <button style={{ marginLeft: '20px', width: '120px' }} onClick={next}>
              Next
            </button>
          </div>
        )}
      </div>
    );
  }

  function addInstruction() {
    return (
      <div className='PurchaseSeat'>
        <h2 style={{ marginTop: '30px' }}>{seats4Date.currentDate.toLocaleDateString(undefined, options)}</h2>
        <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
          <table className='daySeat'>
            <tbody>
              <tr>
                <th style={{ width: '40px' }}> #ID </th>
                <th style={{ width: '100px', minWidth: '100px' }}> Center Name </th>
                <th style={{ width: '600px', minWidth: '300px' }}> Seat unit time and instruction </th>
              </tr>
              {showPurchasedSeatSlot()}
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: '40px' }}>
          {cntDeleted !== 0 && <p style={{ color: 'brown' }}>You are canceling {cntDeleted} time slots</p>}
          <button style={{ width: '120px' }} onClick={back}>
            Back
          </button>
          <button style={{ marginLeft: '20px', width: '120px' }} onClick={confirmUpdate}>
            Confirm
          </button>
        </div>
      </div>
    );
  }
  return seats4Date.stage ? addInstruction() : pickUnits();
};

/*
  <th style={{ width: '500px', minWidth: '300px' }} colSpan={8}> Seat unit time and instruction </th>
 */
export default PurchaseSeat;
