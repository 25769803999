/// this is a functional component

//import { send } from 'process';
import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DataProvider } from '../dataProvider/DataProvider';

//import{IUserInfo} from '../dataProvider/user'

import './Account.css';

interface IAccountProps {
  dataProvider: DataProvider;
  signInOut: any;
  signIn: boolean;
}

const Account = (props: IAccountProps) => {
  const [accountState, setAccountState] = useState({
    agreement: true,
    userId: '',
    password: '',
    displayName: '',
    userPhone: '',
    userEmail: '',
    userAddress: '',
    userContactName: '',
    changePass: '',
    newPass: '',
    confirmPass: '',
    reset: !!props.dataProvider.getSignInState().reset
  });

  const history = useHistory();

  const load = () => {
    props.dataProvider.getUserData().then((data: any) => {
      setAccountState({
        ...accountState,
        displayName: data.displayName,
        userPhone: data.userPhone,
        userEmail: data.userEmail,
        userAddress: data.userAddress,
        userContactName: data.contactName
      });
      //console.log('contact: ' + data.userContactName);
      //console.log('userContactName: ' + accountState.userContactName);
    });
  };

  useEffect(load, []);

  /*
  useEffect(() => {
    //TODO: Derek, fill in your call to dataProvider to get account information
    //  you should need to add more fields in te useState to record the data you received
    //  from the REST API.

    async function getAccountData() {
      const data = props.dataProvider.getUserData();

      setAccountState({...accountState, displayName: data.displayName, userPhone: data.userPhone, userAddress: data.userAddress, userContactName: data.userContactName});
    }

    getAccountData();
  }, []);
  */
  const onClickAgreement = () => {
    setAccountState({ ...accountState, agreement: !accountState.agreement });
  };

  const changeUserId = (e: any) => {
    setAccountState({ ...accountState, userId: e.target.value });
  };

  const changePwd = (e: any) => {
    setAccountState({ ...accountState, password: e.target.value });
  };

  const changeUserDisplayName = (e: any) => {
    setAccountState({ ...accountState, displayName: e.target.value });
  };

  const changeUserEmail = (e: any) => {
    setAccountState({ ...accountState, userEmail: e.target.value });
  };

  const changeUserContactName = (e: any) => {
    setAccountState({ ...accountState, userContactName: e.target.value });
  };

  const changeUserPhone = (e: any) => {
    setAccountState({ ...accountState, userPhone: e.target.value });
  };

  const changeUserAddress = (e: any) => {
    setAccountState({ ...accountState, userAddress: e.target.value });
  };

  const changeChangePass = (e: any) => {
    setAccountState({ ...accountState, changePass: e.target.value });
  };

  const changeNewPass = (e: any) => {
    setAccountState({ ...accountState, newPass: e.target.value });
  };

  const changeConfirmPass = (e: any) => {
    setAccountState({ ...accountState, confirmPass: e.target.value });
  };

  const saveChanges = async () => {
    console.log('saveChanges called');

    const success = await props.dataProvider.updateUser(accountState);
    if (success) {
      alert('user data successfully updated');
      return;
    }
    alert('Failed to update user data');
  };

  const sendTempPwd = async () => {
    if (accountState.userId === '') {
      alert('Must enter a userId');
      return;
    }
    const success = await props.dataProvider.sendTempPwd(accountState.userId);

    console.log(success);

    if (success) {
      alert('temporary password created, please check your email');
      return;
    }
    alert('Failed to create temporary password, please enter a valid userId' + accountState.userId);
  };

  const submitLogin = async () => {
    if (!accountState.agreement) {
      alert('You must select agreement before you can login');
      return;
    }

    // Now, invoke login
    // and this should return to the main page
    //== for now, stay on this page, with the account info?
    const success = await props.dataProvider.login(accountState);
    if (success) {
      // @ts-ignore: history property exits, but not in the property interface definition
      props.signInOut(true);
      if (props.dataProvider.getSignInState().reset) {
        setAccountState({ ...accountState, reset: props.dataProvider.getSignInState().reset });
      } else {
        history.push('./');
      }
      return;
    }
    alert('Sign in failed, please try again');
  };

  const changePassword = async () => {
    if (accountState.newPass !== accountState.confirmPass) {
      alert('New password must match confirmed password');
      return;
    }

    const success = await props.dataProvider.changePwd2(accountState);
    if (success) {
      alert('Password Successfully Updated');
      return;
    }
    alert('Password not updated: Old password was incorrect');
  };

  const initPassword = async () => {
    if (accountState.newPass !== accountState.confirmPass) {
      alert('New password must match confirmed password');
      return;
    }

    const success = await props.dataProvider.changePwd(accountState);
    if (success) {
      alert('Password Successfully Updated');
      props.dataProvider.getSignInState().reset = false;
      history.push('./');
      return;
    }
    alert('Password not updated. Please make sure the password is long enough.');
  };

  function login() {
    return (
      <div className='Account'>
        <h2>Login / Signup</h2>
        <p>Center ID</p>
        <input type='text' value={accountState.userId} onChange={changeUserId} />
        <p style={{ marginTop: '20px' }}>Password</p>
        <p className='instruction'>
          Note: if you don&apos;t have a password setup with our system yet, you need to contact derek@myda.com to add
          your center and your official email into our system. And you can then click the below button to receive a temp
          password to your official email.
        </p>
        <p>
          <button onClick={sendTempPwd}>Send me temp password</button>
        </p>
        <input style={{ marginTop: '20px' }} type='password' value={accountState.password} onChange={changePwd} />
        <p style={{ marginTop: '20px', fontSize: '12px' }}>
          <input type='checkbox' id='Terms' checked={accountState.agreement} onClick={onClickAgreement} />I agree with
          the
          <label htmlFor='Terms'>
            <span style={{ color: 'red' }}>
              <b> terms and conditions</b>
            </span>
          </label>
        </p>
        <button style={{ width: '80px', marginTop: '20px' }} onClick={submitLogin}>
          login
        </button>
        <br />
        <button style={{ width: '80px', marginTop: '20px' }} onClick={() => history.push('./')}>
          back
        </button>
      </div>
    );
  }

  function displayAccount() {
    return (
      <div className='Account'>
        <h1>Account</h1>
        <p>Login ID: {props.dataProvider.getSignInState().userId}</p>

        <table>
          <p style={{ textAlign: 'right' }}>
            Display Name:{' '}
            <input
              type='text'
              value={props.dataProvider.getSignInState().displayName}
              onChange={changeUserDisplayName}
            />
          </p>

          <p style={{ textAlign: 'right' }}>
            Contact Name: <input type='text' value={accountState.userContactName} onChange={changeUserContactName} />
          </p>
          <p style={{ textAlign: 'right' }}>
            Email: <input type='text' value={accountState.userEmail} onChange={changeUserEmail} />
          </p>
          <p style={{ textAlign: 'right' }}>
            Phone Number: <input type='text' value={accountState.userPhone} onChange={changeUserPhone} />
          </p>
          <p style={{ textAlign: 'right' }}>
            Address: <input type='text' value={accountState.userAddress} onChange={changeUserAddress} />
          </p>
          <p style={{ textAlign: 'right' }}>
            Password: <input type='password' onChange={changeChangePass} />
          </p>
          <p style={{ textAlign: 'right' }}>
            New Password: <input type='password' onChange={changeNewPass} />
          </p>
          <p style={{ textAlign: 'right' }}>
            Confirm Password: <input type='password' onChange={changeConfirmPass} />
          </p>

          <button style={{ width: '120px', marginTop: '5px', marginLeft: '88px' }} onClick={saveChanges}>
            make changes
          </button>
          <button style={{ width: '80px', marginTop: '5px', marginLeft: '5px' }} onClick={() => history.push('./')}>
            back
          </button>
          <br />
          <button style={{ width: '120px', marginTop: '5px', marginLeft: '88px' }} onClick={changePassword}>
            change password
          </button>

          <button
            style={{ width: '80px', marginTop: '5px', marginLeft: '5px' }}
            onClick={() => {
              props.signInOut(false);
              history.push('./');
            }}>
            Sign out
          </button>
        </table>
      </div>
    );
  }

  function newAccountSetup() {
    return (
      <div className='setInfo'>
        <h1>Setup a New Password</h1>
        <p>Login ID: {props.dataProvider.getSignInState().userId}</p>
        <p>New Password</p>
        <input type='password' onChange={changeNewPass} />
        <p>Re-Enter New Password</p>
        <input type='password' onChange={changeConfirmPass} />
        <p></p>
        <button onClick={initPassword}>Make Changes</button>
      </div>
    );
  }

  if (!props.signIn) return login();
  else if (accountState.reset) return newAccountSetup();
  else return displayAccount();
};

export default Account;
