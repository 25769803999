import { timeSlots, sqlTimeSlots } from './TimeSlot';
import { ISeatCount, ISeat4Edit } from './SeatCount';
import { IUserInfo } from './user';
import qs from 'querystring';

// eslint-disable-next-line
//import { getAuthStr, wipeSignInState} from '../SharedCommon/utils';

import axios from 'axios';

export class DataProvider {
  private serverUrl: string;
  private loginUrl: string;
  private signInState: any;
  private tempPassUrl: string;
  private resetPwdUrl: string;

  constructor(url: string) {
    this.serverUrl = url;
    this.loginUrl = this.serverUrl + '/user/login';
    this.signInState = JSON.parse(localStorage.getItem('SignInState') || '{}');
    this.tempPassUrl = this.serverUrl + '/user/tempPassword';
    this.resetPwdUrl = this.serverUrl + '/user/resetPassword';
  }

  public getDateString2(d: Date) {
    return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;
  }

  //  app.post('/sharedTime/updateSeatOffer', useAuth, updateSeatOffer);
  public async updateSeatOffer(data: any): Promise<any> {
    try {
      const url = this.serverUrl + '/sharedTime/updateSeatOffer';
      console.log('data sent', data);
      const response1 = await axios.post(url, data, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (response1.status === 200) {
        console.log('updateSeatOffer returned: ', response1.data);
        return response1.data;
      }
    } catch (exception) {
      console.log(exception);
    }
    return {} as any;
  }

  //  app.get('/sharedTime/:date/showSeats2Edit', useAuth, showSeats2Edit);
  public async getShowSeats2Edit(day: Date): Promise<ISeat4Edit[]> {
    try {
      const url = this.serverUrl + `/sharedTime/${this.getDateString2(day)}/showSeats2Edit`;
      const response1 = await axios.get(url, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (response1.status === 200) {
        console.log('showSeats2Edit returned: ', response1.data);
        return response1.data;
      }
    } catch (exception) {
      console.log(exception);
    }
    return [];
  }

  //app.post('/sharedTime/updateSeatTimeUnit', useAuth, updateSeatTimeUnit);
  public async updateSeatTimeUnit(data: any): Promise<any> {
    //  {
    //    purchase: [{sharedSeatID, seatTimeUnitIds:[seatTimeUnitId], messageToAssistant}],
    //    cancelPurchase: [{sharedSeatID, seatTimeUnitIds: [seatTimeUnitId]}]
    //  }

    try {
      const url = this.serverUrl + '/sharedTime/updateSeatTimeUnit';
      console.log('data sent', data);
      const response1 = await axios.post(url, data, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (response1.status === 200) {
        console.log('updateSeatTimeUnit returned: ', response1.data);
        return response1.data;
      }
    } catch (exception) {
      console.log(exception);
    }
    return {} as any;
  }

  //  app.get('/sharedTime/:date/showSeatTimeUnit', showSeatTimeUnit);
  public async showSeatTimeUnit(day: Date) {
    try {
      const url = this.serverUrl + `/sharedTime/${this.getDateString2(day)}/showSeatTimeUnit`;
      const response1 = await axios.get(url, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (response1.status === 200) {
        console.log('showSeatTimeUnit returned: ', response1.data);
        return response1.data;
      }
    } catch (exception) {
      console.log(exception);
    }
    return [];
  }

  public async getUnPurchasedSeats(mon: Date): Promise<ISeatCount[]> {
    try {
      const url = this.serverUrl + `/sharedTime/${this.getDateString2(mon)}/getUnpurchasedSeats`;
      const response1 = await axios.get(url, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (response1.status === 200) {
        console.log('getUnpurchasedSeats returned: ', response1.data);
        return response1.data;
      }
    } catch (exception) {
      console.log(exception);
    }
    return [];
  }

  public async getSeatsOffered(mon: Date): Promise<ISeatCount[]> {
    if (!this.isSignedIn()) return [];
    try {
      const url = this.serverUrl + `/sharedTime/${this.getDateString2(mon)}/showOfferedSeat`;
      const response1 = await axios.get(url, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (response1.status === 200) {
        console.log('showOfferedSeat returned: ', response1.data);
        return response1.data;
      }
    } catch (exception) {
      console.log(exception);
    }
    return [];
  }

  public async getseatsPurchased(mon: Date): Promise<ISeatCount[]> {
    if (!this.isSignedIn()) return [];
    try {
      const url = this.serverUrl + `/sharedTime/${this.getDateString2(mon)}/showPurchasedSeat`;
      const response1 = await axios.get(url, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (response1.status === 200) {
        console.log('showPurchasedSeat returned: ', response1.data);
        return response1.data;
      }
    } catch (exception) {
      console.log(exception);
    }
    return [];
  }

  //createSeatOffer, returns an array of shareSeatId
  public async createSeatOffer(offerData: any): Promise<boolean> {
    try {
      const url = this.serverUrl + '/sharedTime/createSeatOffer';
      const s = timeSlots.indexOf(offerData.startT);
      const e = timeSlots.indexOf(offerData.endT);

      console.log(e);

      const seatOfferData = {
        date: offerData.dt,
        assistantName: offerData.assist,
        onlineInfo: offerData.onlineInfo,
        startTime: sqlTimeSlots[s],
        endTime: sqlTimeSlots[e],
        seedN: offerData.seedN,
        recurring: offerData.recurring,
        repeatCount: offerData.repeatCount
      };

      const response1 = await axios.post(url, seatOfferData, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (!offerData.recurring) {
        seatOfferData.repeatCount = 0;
      }

      if (response1.status === 200) {
        const createSeatUnitUrl = this.serverUrl + '/sharedTime/createSeatUnit';

        const sharedSeatIds = response1.data;

        //console.log(sharedSeatIds);

        //console.log(sharedSeatIds.length, offerData.seedN, typeof offerData.seedN);
        //console.log(sharedSeatIds.length);
        //console.log(offerData.seedN);
        //console.log(seatOfferData.repeatCount)
        //console.log("Seed * Repeat: " + parseInt(offerData.seedN)*(parseInt(seatOfferData.repeatCount) + 1));
        //console.log(offerData.repeatCount);
        //TODO: Fix if statement - DONE
        if (sharedSeatIds.length !== parseInt(offerData.seedN) * (parseInt(seatOfferData.repeatCount) + 1)) {
          console.log('not all seats are created!');
          return false;
        }

        for (let re = 0; re < parseInt(seatOfferData.repeatCount) + 1; re++) {
          console.log('Loop: ' + re);
          for (let ii = 0; ii < sharedSeatIds.length / (parseInt(seatOfferData.repeatCount) + 1); ii++) {
            let startTimes = '';
            for (let jj = 0; jj < e - s; jj++) {
              if (!offerData.seedSlotState[ii * (e - s) + jj]) {
                if (startTimes) startTimes += ',';
                startTimes += sqlTimeSlots[jj + s];
              }
            }
            if (!startTimes) {
              continue; /// for this seatId, there was no time slot
            }
            const seatUnitData = {
              sharedSeatId: sharedSeatIds[ii * (parseInt(seatOfferData.repeatCount) + 1) + re],
              startTimes
            };

            console.log(seatUnitData.sharedSeatId);

            //console.log(seatUnitData.sharedSeatId);

            const response2 = await axios.post(createSeatUnitUrl, seatUnitData, {
              headers: { Authorization: this.getAuthStr() }
            });
            if (response2.status !== 200) {
              console.log('createSeatUnit failed: ', response2.status, response2.data);
              return false;
            }
          }
        }
        return true;
      }
      console.log('createSeatOffer failed: ', response1.status, response1.data);
    } catch (exception) {
      console.log(exception);
    }
    return false;
  }

  public async getUserData(): Promise<IUserInfo[]> {
    try {
      const getUserDataUrl = this.serverUrl + '/user/displayUserInfo';
      const response = await axios.get(getUserDataUrl, {
        headers: { Authorization: this.getAuthStr() }
      });

      if (response.status === 200) {
        console.log('displayUserInfo returned: ', response.data);
        return response.data;
      }
    } catch (exception) {
      console.log(exception);
    }
    return [];
  }

  public async sendTempPwd(userData: any) {
    try {
      const data = { userId: userData };

      //console.log('sendTempPwd called: ' + this.tempPassUrl);
      //console.log('userData: ' + userData);

      const response = await axios.put(this.tempPassUrl, qs.stringify(data));

      if (response.status === 200) {
        console.log('sendTempPwd returned: ', response.data);
      }
      return true;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  }

  public async changePwd(userData: any) {
    try {
      const url = this.serverUrl + '/user/create';
      const response = await axios.post(url, userData, {
        headers: { Authorization: this.getAuthStr() }
      });
      if (response.status === 200) {
        console.log('sendTempPwd returned: ', response.data);
      }
      return true;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  }

  public async changePwd2(userData: any) {
    try {
      const url = this.serverUrl + '/user/changePwd';
      const response = await axios.post(url, userData, {
        headers: { Authorization: this.getAuthStr() }
      });
      if (response.status === 200) {
        console.log('sendTempPwd returned: ', response.data);
      }
      return true;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  }

  public async updateUser(userData: any) {
    try {
      //console.log('updateUserFunction');
      const url = this.serverUrl + '/user/updateInfo';

      //console.log(url);

      const response = await axios.post(url, userData, { headers: { Authorization: this.getAuthStr() } });
      if (response.status === 200) {
        console.log('updateUser returned: ', response.data);
      }
      return true;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  }

  public async login(loginData: any): Promise<boolean> {
    try {
      const data = {
        userId: loginData.userId,
        password: loginData.password
      };

      const response = await axios.post(this.loginUrl, qs.stringify(data));

      if (response.status === 200) {
        console.log('signin returned: ', response.data);

        //signInState is an object {userId, accessToken, displayName}
        this.signInState = {
          userId: loginData.userId,
          ...response.data
        };
        localStorage.setItem('SignInState', JSON.stringify(this.signInState));
        //console.log('reset: ' + this.signInState.reset);
      }
      return true;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  }

  public logout() {
    this.signInState.accessToken = '';
    this.signInState.displayName = '';
    localStorage.setItem('SignInState', JSON.stringify(this.signInState));
  }

  public getSignInState(): any {
    return this.signInState;
  }
  public isSignedIn() {
    return !!this.signInState.accessToken;
  }

  private getAuthStr() {
    const sss = localStorage.getItem('SignInState');
    const signInState = JSON.parse(sss || '{}');
    return 'Bearer '.concat(signInState?.accessToken ? signInState.accessToken : '');
  }
}
