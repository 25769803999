import './App.css';
import Header from './components/Header';
//import DiscoverStudy from './components/DiscoverStudy';
import Calendar from './components/Calendar';
import PurchaseSeat from './components/PurchaseSeat';
import Account from './components/Account';
import PostSeat from './components/PostSeat';
import EditSeat from './components/EditSeat';

import { DataProvider } from './dataProvider/DataProvider';

import React from 'react';

import {
  //BrowserView,
  //MobileView,
  //isBrowser,
  isMobile
} from 'react-device-detect';

import {
  //BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter
  //Link
} from 'react-router-dom';

const LocalHostURL = 'http://localhost:5000';
const RemoteHostURL = 'https://kcenter.top/api';

interface IAppState {
  signedIn: boolean;
  date: Date;
}

class App extends React.Component<{}, IAppState> {
  private _dataProvider: DataProvider;
  private serverUrl: string;

  constructor(props: {}) {
    super(props);

    let local = window.location.href.indexOf('localhost') !== -1;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('local')) {
      local = urlParams.get('local') === '1';
    }

    this.serverUrl = local ? LocalHostURL : RemoteHostURL;
    this._dataProvider = new DataProvider(this.serverUrl);

    this.state = {
      signedIn: this._dataProvider.isSignedIn(),
      date: new Date()
    };
  }

  private _signInOut = (signedIn: boolean): void => {
    if (!signedIn) {
      this._dataProvider.logout();
    }
    this.setState({ signedIn });
  };

  private getBasename = (path: string) => {
    return path.substr(0, path.lastIndexOf('/'));
  };

  private _setDateDetail = (date: Date) => {
    this.setState({ date });
  };
  public render(): React.ReactNode {
    if (isMobile) {
      return (
        <div>
          <br />
          This webapp only works on PC/Mac.
          <br />
          Please use iDigest app in the <br />
          appstore on mobile devices
        </div>
      );
    }

    return (
      <BrowserRouter basename={this.getBasename(window.location.pathname)}>
        <div className='headerDiv'>
          <Header signInState={this.state.signedIn} dataProvider={this._dataProvider} />
        </div>
        <Switch>
          <Route path='/account'>
            <Account dataProvider={this._dataProvider} signInOut={this._signInOut} signIn={this.state.signedIn} />
          </Route>
          <Route path='/PurchaseSeat'>
            <PurchaseSeat
              currentDate={this.state.date}
              dataProvider={this._dataProvider}
              signIn={this.state.signedIn}
            />
          </Route>
          <Route path='/postoffer'>
            <PostSeat dataProvider={this._dataProvider} signIn={this.state.signedIn} />
          </Route>
          <Route path='/editoffer'>
            <EditSeat dataProvider={this._dataProvider} date={this.state.date} />
          </Route>
          <Route
            path='/'
            render={
              // @ts-ignore: history property exits, but not in the property interface definition
              (props) => <Calendar setDateDetail={this._setDateDetail} dataProvider={this._dataProvider} {...props} />
            }></Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
